<template>
  <component :is="FooterTemplate" />
</template>

<script setup>
const storeConfig = useStoreConfig()
const { themeComponents } = storeToRefs(storeConfig)

const FooterTemplate = shallowRef()
switch(unref(themeComponents)?.footer_component) {
  case '2':
    FooterTemplate.value = resolveComponent('LazyTemplatesFooterLayout2')
    break;
  case '3':
    FooterTemplate.value = resolveComponent('LazyTemplatesFooterLayout3')
    break;
  case '4':
    FooterTemplate.value = resolveComponent('LazyTemplatesFooterLayout4')
    break;
  case '5':
    FooterTemplate.value = resolveComponent('LazyTemplatesFooterLayout5')
    break;
  default :
    FooterTemplate.value = resolveComponent('LazyTemplatesFooterLayout1')
}
</script>